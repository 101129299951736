import React from "react";
import { withRouter } from "react-router-dom";
import { flowRight as compose } from 'lodash';
import classnames from "classnames";
import { withStore } from "../../store";
import Fetch from "../../components/Fetch/Fetch";
import PerformanceParser from "../../parser/PerformanceParser";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";
import Loading from "../../components/Loading/Loading";
import { getClientApiURL, isShortFormContent, getTextFromHtml } from "../../utils"
import "./PerformancePage.scss";

const PerformancePage: React.FunctionComponent<any> = ({ store: { language }, history }) => {
  const url = `${getClientApiURL(window.location.pathname.replace("/detail", ""))}`;

  return (
    <Fetch
      key={url}
      url={url}
      addMiddlewareHeaders
      options={{ credentials: "include" }}
      authzBeforeRequest
      >
      {(loading, errors, data) => {
        let screen = PerformanceParser(data || {});
        const mediaType = data && data.mediaType;
        document.title = getTextFromHtml(screen.title);

        return (
          errors ?
            <ErrorOverlay /> :
            <div className={classnames("PerformancePage", {"short-form": isShortFormContent(mediaType)})}>
              { loading && <Loading /> }
              { data && screen.components }
            </div>
        )
      }}
    </Fetch>
  )
};

export default compose(withRouter, withStore)(PerformancePage);
