import _ from "lodash";
import moment from "moment";

export const formatDate = (dateString: string, withYear = true) => {
  dateString = dateString.split('T')[0];
  let format = `MMM DD`;
  if (withYear) {
    format += `, YYYY`;
  }
  return moment(dateString).format(format);
};

export const getFormattedDateTime = (date) => {
  const fullDate = new Date(date),
    year = fullDate.getFullYear(),
    month = fullDate.getMonth() + 1,
    day = fullDate.getDate(),
    time = getFormattedTime(date);
  return `${month}/${day}/${year} | ${time}`;
};


export const formatBroadcastDate = (dateTime) => {
  const date = new Date(dateTime)
  const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  const day = days[date.getDay()];
  const month = months[date.getMonth()];
  const dateNumber = String(date.getDate()).padStart(2, '0');

  return `${day}, ${month} ${dateNumber}`;
}

export const getFormattedTime = (dateTime) => {
  const date = new Date(dateTime);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ampm;
  return strTime;
};

export const getPerformanceYearRange = (date) => {
  let year = new Date(date).getFullYear();
  return (Math.floor(year / 10) * 10);
}

export const pluralize = (string, suffix = 's') => {
  return `${string}${suffix}`;
}

export const getMediaTypeCounts = (tiles) => {
  const mediaTypeCounts = {"video": 0, "audio": 0};
  _.forEach(tiles, function(tile) {
    if (tile.mediaType === "video" || tile.mediaType === "short_form_video") {
      mediaTypeCounts["video"]++;
    } else if (tile.mediaType === "audio") {
      mediaTypeCounts["audio"]++;
    }
  });
  return mediaTypeCounts;
}

export const isElementInViewport = (el) => {
  var rect = el.getBoundingClientRect();

  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const formatDuration = (milliseconds) => {
  let totalSeconds = milliseconds / 1000;
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds % 3600) / 60);
  let seconds = Math.ceil(totalSeconds - (hours * 3600) - (minutes * 60));

  if(seconds === 60) {
    minutes += 1;
    seconds = 0;
  }

  let duration = "";
  if(hours) duration += `${hours} HRS `;
  if(minutes) duration += `${minutes} MIN `
  if(seconds && !hours) duration += `${seconds} SEC`;

  return duration;
}

export const getCastByType = (cast, type, fullname?) => {
  const composers = cast.filter(cast => {
    return cast.role === type.toUpperCase()
  });
  return composers.length ? (!fullname && composers[0].person.sort) || composers[0].person.name : '';
}

export const getComposerLastName = (cast) => {
  if (cast) {
    const composer = cast.find((castMember) => castMember.role === "COMPOSER" && castMember.person.sort);
    if (composer) return composer.person.sort;
  }
  return "";
}

export const getCastAndCrew = (cast, justNames = false) => {
  const arr = cast && cast.filter(person => {
    const role = person.role && person.role.toLowerCase();
    return role && (role !== "composer" && role !== "librettist" && role !== "group");
  });
  if (justNames) {
    return arr.map(cast => cast.person.name).join(', ');
  }
  return arr;
}

export const getAllRoles = (roles) => {
  const roleTypes = _.uniqBy(roles, "role").map(role => {
    return role.role;
  });
  return roleTypes.length > 0  ? roleTypes.join(", ") : '';
}

export const getTileInfo = ({
  id,
  extId,
  mediaType,
  briefSynopsis,
  fullSynopsis,
  fullSynopsisUrl,
  ticketURL,
  runTime,
  liveDuration,
  resolutionType,
  performanceDate,
  performanceDateISO,
  start,
  end,
  cast,
  previewableChapters,
  hasAudioDescription,
  hasCaptions,
  isFavorite,
  position,
  tier,
  tile,
  name,
  donateURL,
  dvdURL,
  merchandiseURL,
  seeAtMetURL,
  sponsorship,
  buyOnItunesURL,
  parentPerformance,
  shortCast
}) => {

  const {
    header,
    subHeader,
    image,
    badges,
    ctaLabel
  } = tile || {};

  return {
    id,
    extId,
    mediaType: mediaType && mediaType.toLowerCase(),
    header: header || name,
    subHeader,
    briefSynopsis,
    fullSynopsis,
    fullSynopsisUrl,
    ticketURL,
    runTime,
    liveDuration,
    resolutionType,
    imageurl: image && image.url,
    badge: badges && badges[0],
    ctaLabel,
    performanceDate,
    performanceDateISO,
    startDate: start,
    endDate: end,
    cast,
    previewableChapters,
    hasAudioDescription,
    hasCaptions,
    url: `/performance/detail/${id}`,
    isFavorite,
    position,
    tier,
    donateURL,
    dvdURL,
    merchandiseURL,
    seeAtMetURL,
    sponsorship,
    buyOnItunesURL,
    parentPerformance,
    shortCast
  }
}

export const getPersonInfo = (person) => {
  const id = person.id;
  const name = person.name;
  const roles = person.roles;
  const { image } = person.tile || {};
  const url = `/person/${id}`;

  return {
    id,
    name,
    roles,
    imageurl: image && image.url,
    url
  }
}

export const featutesInTileMapper = (content) => {
  return content.map(item => {
    if (item.performance) {
      return getTileInfo(item.performance);
    }
    return false;
  });
}

export const childrenTileMapper = (content) => {
  return content.map(item => {
    const mediaType = item.mediaType && item.mediaType.toLowerCase();
    if (mediaType && (mediaType === "video" || mediaType === "audio" || isShortFormContent(mediaType) || mediaType === "audio_stream")) {
      return getTileInfo(item);
    } else if (item.roles) {
      return getPersonInfo(item);
    }
    return false;
  });
}

export const getActiveItem = (items) => {
  const currentTime = new Date();
  const upcoming = [], live = [], completed = [];
  _.forEach(items, function(item) {
    const startTime = new Date(item.start);
    const endTime = new Date(item.end);
    if (startTime && endTime) {
      if (Date.parse(currentTime) > Date.parse(startTime) && Date.parse(currentTime) <= Date.parse(endTime)) {
        live.push(item);
      } else if (Date.parse(currentTime) > Date.parse(endTime)) {
        completed.push(item);
      } else {
        upcoming.push(item);
      }
    }
  });
  return live.length > 0 ? live[0] : null;
}

export const liveAndUpcomingItems = (items) => {
  const currentTime = new Date();
  return items.filter(item => {
    const startTime = new Date(item.start);
    const endTime = new Date(item.end);
    const isLiveOrUpcoming = (startTime && endTime && Date.parse(currentTime) <= Date.parse(endTime))
    return isLiveOrUpcoming;
  });
}

export const upcomingItems = (items) => {
  const currentTime = new Date();
  return items.filter(item => {
    const startTime = new Date(item.start);
    return currentTime < startTime;
  });
}

export const isItemLive = (item) => {
  const currentTime = new Date();
  const startTime = new Date(item.start);
  const endTime = new Date(item.end);
  if (startTime && endTime) {
    if (Date.parse(currentTime) > Date.parse(startTime) && Date.parse(currentTime) <= Date.parse(endTime)) {
      return true;
    } else {
      return false;
    }
  }
}

export const getDynamicStrings = (items) => {
  const dynamicStrings = {}
  _.forEach(items, function(item) {
    const key = item["key"];
    dynamicStrings[key] = item["value"];
  });
  return dynamicStrings;
}

export const getPageType = () => {
  const pathname = window.location.pathname;
  let pageType;
  if (pathname.indexOf('performance') > -1) {
    pageType = 'performance';
  } if (pathname.indexOf('broadcast') > -1) {
    pageType = 'broadcast';
  }
  return pageType;
}

export const toggleAccordion = (event) => {
  const selectedAccordion = event.target.closest(".accordion");
  // @ts-ignore
  document.querySelectorAll(".accordion").forEach((el) => {
    if (!el.isEqualNode(selectedAccordion)) {
      el.classList.remove("expanded");
    } else {
      el.classList.toggle("expanded");
    }
  });
}

export const fetchHandler = (url, options, onSuccess, onError) => {
  let opts = {...options, credentials: "include"}
  _.set(opts, "headers.user-platform", "Web")
  _.set(opts, "headers.origin-hostname", window.location.hostname)
  fetch(url, opts)
    .then( async (response) => {
      if (response.ok && (response.status !== 401)) {
        return response.text()
      } else {
        try {
          const text = await response.text();
          const data = JSON.parse(text);

          if (data.status === 429) {
            return data
          } else {
            throw new Error(data);
          }
        } catch(err) {
          throw new Error(err);
        }
      }

    })
    .then(text => {
      try {
        const data = JSON.parse(text);
        onSuccess && onSuccess(data);
      } catch(err) {
        onSuccess && onSuccess(text);
      }
    }).catch((error) => {
      onError && onError(error);
    });
}

// export const getClientApiURL = () => {
//    return window.location.hostname === process.env.REACT_APP_HOSTNAME ? process.env.REACT_APP_CLIENT_API_URL : "/proxy"
// }

export const getClientApiURL = (path) => {
  const isProxiedSite = window.location.hostname !== process.env.REACT_APP_HOSTNAME
  // const isProxiedSite = false
  const baseUrl = !isProxiedSite
      ? process.env.REACT_APP_CLIENT_API_URL
      : `${window.location.origin}/proxy`;

  let fullUrl

  if (isProxiedSite) {
    fullUrl = new URL(baseUrl + (path ? path : ""));
    fullUrl.searchParams.set('origin_hostname', window.location.hostname);
  } else {
    fullUrl = new URL(baseUrl + (path ? path : ""));
  }
  // Return the full URL string
  return fullUrl.toString();
}

export const isShortFormContent = (mediaType) => {
  return mediaType && ['short_form_video', 'short_form_audio'].includes(mediaType.toLowerCase());
}

export const getTextFromHtml = (html) => {
  let doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
}
